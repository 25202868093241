<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">

                <SettingMenu></SettingMenu>


                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                            <div class="section-title margin-deliver">{{ $t(`Custom Domain`) }}
                                <v-popover offset="10" :disabled="false" style="display:initial;">
                                    <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                    <template slot="popover">
                                        <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                        <div style="text-align:left;">
                                            <a v-close-popover
                                               href="https://easy.jobs/docs/how-to-set-up-a-custom-domain-in-easy-jobs"
                                               target="_blank">
                                                {{ $t('How to Set-up a Custom Domain?') }}
                                            </a>
                                        </div>

                                    </template>
                                </v-popover>
                            </div>
                    </div>

                    <form action="">
                        <div class="form-box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="custom-domain-label" style="font-size: 20px;">
                                        <p><b>Step 1:</b> Create a <span>CNAME</span> record from your DNS setting</p>
                                        <p><b>Step 2:</b> {{ $t(`Add preferable`) }} <span>hostname</span> {{ $t(`is an alias of`) }}
                                            <code>custom.easy.jobs</code> <code
                                                    @click.prevent="copyToClipboard('custom.easy.jobs')"
                                                    class="eicon e-duplicate"></code></p>
                                        <p><b>Step 3:</b> Wait for a while until your <span>CNAME record</span> is publicly
                                            discoverable.</p>
                                        <p><b>Step 4:</b> Once it’s been verified successfully you can go further adding your custom
                                            domain. Or if not your data will be not saved and notified by an error
                                            message.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="mt-3">{{ $t(`Custom Domain`) }}</label>
                                        <input type="text" name="" ref="cname" v-model="form.cname"
                                               v-bind:class="{ 'has-error': errors.cname.length }" class="form-control"
                                               placeholder="your.domain.dns" autofocus/>
                                        <div class="error-message" v-show="errors.cname">
                                            {{ errors.cname ? $t(errors.cname.join(' ')) : '' }}
                                        </div>
                                        <div class="form-note">
                                            <span style="text-transform:uppercase;">{{ $t(`tips`) }}: </span>{{ $t(`cname-help-text`) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" v-if="showScript">
                                        <label class="mt-3">{{ $t(`Custom Script`) }}</label>
                                        <textarea name="" v-model="form.script" cols="30" rows="7" class="form-control"
                                                  :placeholder="$t('write custom script here')"></textarea>
                                        <div class="error-message" v-show="errors.script">
                                            {{ errors.script ? errors.script.join(' ') : '' }}
                                        </div>
                                    </div>
                                    <div class="form-group mt-5">
                                        <submit-button :click="saveCustomDomain" :loading="isLoading">{{ $t(`Save`) }}
                                        </submit-button>
                                    </div>
                                </div>

                            </div>
                            <div class="box mt-3">
                                <h4 class="mb-3">{{ $t(`How to create CNAME record?`) }}</h4>
                                <img src="/img/hint/customdomain.png" alt="" width="100%">
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import {UPDATE_COMPANY_DATA} from "../../../constants/action-type";
import {mapActions, mapState} from "vuex";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
    },
    data() {
        return {
            example: false,

            form: {
                cname: '',
                script: ''
            },
            errors: {
                cname: '',
            },
            showScript: false,
            isLoading: false
        }
    },
    computed: {
        ...mapState(['company']),

    },
    methods: {
        ...mapActions([UPDATE_COMPANY_DATA]),
        getCustomDomain() {
            client().get('company/setting/custom-domain').then(({data: {data}}) => {
                this.form.cname = data.cname;
                this.form.script = data.script;
                if (this.form.cname.length > 0) {
                    this.showScript = true;
                }
            });
        },
        saveCustomDomain() {
            if (this.isValid()) {
                this.isLoading = true;
                client().post('company/setting/custom-domain', this.form).then(({data: {data, message}}) => {
                    // this.form.cname = data.custom_domain;
                    this.form.script = data.custom_scripts;
                    if (this.form.cname && this.form.cname.length > 0) {
                        this.showScript = true;
                    }
                    this.$toast.success(this.$t(message));

                    if (data.hasOwnProperty('custom_domain')) {

                        let company = {...this.company, ...{url: data.custom_domain}};

                        this[UPDATE_COMPANY_DATA](company);

                    }
                }).catch(({response: {data, status}}) => {
                    if (status == 480 || status == 490) {
                        this.$toast.error(this.$t(data.message));
                    } else {
                        this.$toast.error(data.message.cname.join(" "));
                        this.errors = data.message;
                    }
                }).finally(res => {
                    this.isLoading = false;
                });
            }

        },
        isValid() {
            this.errors.cname = "";

            if (this.form.cname.length > 200) {
                this.errors.cname = this.$t(`Cname length cannot be grater then 200 `);
                return false;
            }
            return true;
        }
    },
    async created() {
        await this.getCustomDomain();
        await this.$refs.cname.focus();
    }
}
</script>
<style scoped>
.page-body .content-area__body .custom-domain-label p {
    font-size: 1rem;
}

</style>
